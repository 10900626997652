import React, { useReducer, createContext, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';

const initialState = {
    user: null, 
    property: null, 
    land: null,
    mining: null,
}

if (localStorage.getItem("token")) {
    const decodedToken = jwtDecode(localStorage.getItem("token"));

    if (decodedToken.exp * 1000 < Date.now()){
        localStorage.removeItem("token");
    } else {
        initialState.user = decodedToken;
    }
}

const AuthContext = createContext({
    user: null,
    property: null,
    land: null,
    mining: null,
    login: (userData) => {},
    logout: () => {},
    updateProperty: (propertyData) => {},
    updateLand: (landData) => {},
    updateMine: (mineData) => {},
});

function authReducer(state, action) {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                user: action.payload
            }
        
        case 'LOGOUT':
            return {
                ...state,
                user: null,
                property: null,
                land: null,
                mining: null,
            }

        case 'UPDATE_PROPERTY_DETAILS':
            return {
                ...state,
                property: action.payload
            }

        case 'UPDATE_LAND_DETAILS':
            return {
                ...state,
                land: action.payload
            }

            case 'UPDATE_MINES_DETAILS':
                return {
                    ...state,
                    mining: action.payload
                }

        default:
            return state;
    }
}

function AuthProvider(props) {
    const [state, dispatch] = useReducer(authReducer, initialState);

    // Wrap the dispatch function with useCallback to memoize it
    const memoizedDispatch = useCallback((action) => {
        dispatch(action);
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            if (decodedToken.exp * 1000 < Date.now()) {
                memoizedDispatch({ type: 'LOGOUT' });
            } else {
                memoizedDispatch({ type: 'LOGIN', payload: decodedToken });
            }
        } else {
            memoizedDispatch({ type: 'LOGOUT' });
        }
    }, [memoizedDispatch]);

    const login = useCallback((userData) => {
        localStorage.setItem("token", userData.token);
        memoizedDispatch({
            type: 'LOGIN',
            payload: userData
        });
    }, [memoizedDispatch]);

    const logout = useCallback(() => {
        localStorage.removeItem("token");
        memoizedDispatch({ type: 'LOGOUT' });
    }, [memoizedDispatch]);

    const updateProperty = useCallback((propertyData) => {
        memoizedDispatch({
            type: 'UPDATE_PROPERTY_DETAILS',
            payload: propertyData
        });
    }, [memoizedDispatch]);

    const updateLand = useCallback((landData) => {
        memoizedDispatch({
            type: 'UPDATE_LAND_DETAILS',
            payload: landData
        });
    }, [memoizedDispatch]);

    const updateMine = useCallback((mineData) => {
        memoizedDispatch({
            type: 'UPDATE_MINES_DETAILS',
            payload: mineData
        });
    }, [memoizedDispatch]);

    return (
        <AuthContext.Provider
            value={{
                user: state.user, 
                property: state.property,
                land: state.land,
                mining: state.mining,
                login, 
                logout,
                updateProperty,
                updateLand,
                updateMine,
            }}
            {...props}
        />
    );
}

export { AuthContext, AuthProvider};
