import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom';

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Home Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
const Homefour = React.lazy(() => import("./components/pages/Homefour"));
const Homefive = React.lazy(() => import("./components/pages/Homefive"));
// Blog
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Bloglist = React.lazy(() => import("./components/pages/Bloglist"));
const Blogsingle = React.lazy(() => import("./components/pages/Blogsingle"));
// Pages
const About = React.lazy(() => import("./components/pages/About"));
const Services = React.lazy(() => import("./components/pages/Services"));
const Faq = React.lazy(() => import("./components/pages/Faq"));
const Pricing = React.lazy(() => import("./components/pages/Pricing"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Comingsoon = React.lazy(() => import("./components/pages/Comingsoon"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Legal = React.lazy(() => import("./components/pages/Legal"));
const Emailverify = React.lazy(() => import("./components/pages/Emailverify"));
const Forgotpassword = React.lazy(() => import("./components/pages/Forgotpassword"));
const Hotelbookings = React.lazy(() => import("./components/pages/Hotelbookings"));
const Resetpassword = React.lazy(() => import("./components/pages/Resetpassword"));
// Listings
const Listinggrid = React.lazy(() => import("./components/pages/Listinggrid"));
const Listinglist = React.lazy(() => import("./components/pages/Listinglist"));
const Listinglistland = React.lazy(() => import("./components/pages/Listinglistland"));
const Listinglistmines = React.lazy(() => import("./components/pages/Listinglistmines"));
const Listingmap = React.lazy(() => import("./components/pages/Listingmap"));
const Listingdetailsone = React.lazy(() => import("./components/pages/Listingdetailsone"));
// Residential
const Listingdetailstwo = React.lazy(() => import("./components/pages/Listingdetailstwo"));
// Land
const Listingdetailsland = React.lazy(() => import("./components/pages/Listingdetailsland"));
// Mining
const Listingdetailsmines = React.lazy(() => import("./components/pages/Listingdetailsmines"));
const Listingdetailsthree = React.lazy(() => import("./components/pages/Listingdetailsthree"));
const Submitlisting = React.lazy(() => import("./components/pages/Submitlisting"));
const Comparelistings = React.lazy(() => import("./components/pages/Comparelistings"));
// Agents
const Agentarchive = React.lazy(() => import("./components/pages/Agentarchive"));
const Agentdetails = React.lazy(() => import("./components/pages/Agentdetails"));
const Profile = React.lazy(() => import("./components/pages/Profile"));
const Profilelistings = React.lazy(() => import("./components/pages/Profilelistings"));
const Profilesavedlistings = React.lazy(() => import("./components/pages/Profilesavedlistings"));
// Agency
const Agencyarchive = React.lazy(() => import("./components/pages/Agencyarchive"));
const Agencydetails = React.lazy(() => import("./components/pages/Agencydetails"));
// Cateories
const ResLanding = React.lazy(() => import("./components/pages/categories/ResLanding"));
const LandLanding = React.lazy(() => import("./components/pages/categories/LandLanding"));
const MinesLanding = React.lazy(() => import("./components/pages/categories/MinesLanding"));


function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Preloader />
        <Switch >
          {/* Homepages */}
          <Route exact path="/" component={Home} />
          <Route path="/home-v2" component={Hometwo} />
          <Route path="/home-v3" component={Homethree} />
          <Route path="/home-v4" component={Homefour} />
          <Route path="/home-v5" component={Homefive} />
          {/* Blog */}
          <Route path="/blog-grid" component={Bloggrid} />
          <Route path="/blog-list" component={Bloglist} />
          <Route path="/blog-single/:id" component={Blogsingle} />
          {/* Pages */}
          <Route path="/about" component={About} />
          <Route path="/services" component={Services} />
          <Route path="/faq" component={Faq} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/contact" component={Contact} />
          <Route path="/coming-soon" component={Comingsoon} />
          <Route path="/error-404" component={Error} />
          <Route path="/login/:id" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/legal" component={Legal} />
          <Route path="/email-verify/:id" component={Emailverify} />
          <Route path="/forgot-password" component={Forgotpassword} />
          <Route path="/hotel-bookings/:id" component={Hotelbookings} />
          <Route path="/reset-password/:id" component={Resetpassword} />
          {/* Listings */}
          <Route path="/listing-grid" component={Listinggrid} /> 
          {/* Search Results for Residential */}
          <Route path="/listing-list/:id" component={Listinglist} />
          {/* Search Results for Land */}
          <Route path="/listing-listland/:id" component={Listinglistland} />
          {/* Search Results for Mining */}
          <Route path="/listing-listmines/:id" component={Listinglistmines} />
          <Route path="/listing-map" component={Listingmap} />
          <Route path="/listing-details-v1" component={Listingdetailsone} />
          <Route path="/listing-details-v2/:id" component={Listingdetailstwo} />
          <Route path="/listing-details-land/:id" component={Listingdetailsland} />
          <Route path="/listing-details-mines/:id" component={Listingdetailsmines} />
          <Route path="/listing-details-v3" component={Listingdetailsthree} />
          <Route path="/submit-listing" component={Submitlisting} />
          <Route path="/compare-listings" component={Comparelistings} />
          {/* Agents */}
          <Route path="/agent-archive" component={Agentarchive} />
          <Route path="/agent-details" component={Agentdetails} />
          <Route path="/profile" component={Profile} />
          <Route path="/profile-listings" component={Profilelistings} />
          <Route path="/profile-saved-listings" component={Profilesavedlistings} />
          {/* Agency */}
          <Route path="/agency-archive" component={Agencyarchive} />
          <Route path="/agency-details" component={Agencydetails} />
          {/* Categories */}
          <Route path="/res-landing" component={ResLanding} />
          <Route path="/land-landing" component={LandLanding} />
          <Route path="/mines-landing" component={MinesLanding} />
        </Switch >
      </Suspense>
    </Router>
  );
}

export default App;
