import React from 'react';
import whatsappIcon from './whatsapp.svg'; // Path to your SVG file

const WhatsAppIcon = () => {
  return (
    <a href="https://wa.me/+26775594921" // Replace with your WhatsApp number
       target="_blank"
       rel="noopener noreferrer"
       className="whatsapp-icon">
      <img src={whatsappIcon} alt="WhatsApp" className="whatsapp-icon-img" />
    </a>
  );
};

export default WhatsAppIcon;
