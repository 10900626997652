import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from "@apollo/client";
import { client } from "./apolloClient";
import {AuthProvider} from './context/authContext';
import WhatsAppIcon from './components/layouts/Whatsapp';
import ScrollToTop from "react-scroll-to-top";

// Css
import '../node_modules/animate.css/animate.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import '../node_modules/magnific-popup/dist/magnific-popup.css';
import '../node_modules/react-select2-wrapper/css/select2.css';
import '../node_modules/leaflet/dist/leaflet.css';
import './assets/fonts/flaticon/flaticon.css';
import './assets/fonts/font-awesome/css/all.min.css';
import './assets/css/style.css';

// Our React application needs access to...

// Client

// Authorization Contect 

// Browser Router (React Router) /login /register

ReactDOM.render(
  <AuthProvider>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
        <WhatsAppIcon />
        <ScrollToTop 
            smooth 
            color="blue"
            width="20"
            height="20"
            className="scroll-to-top"
        />
      </BrowserRouter>
    </ApolloProvider>
  </AuthProvider>,
  document.getElementById('acres')
);
